import React from 'react';
import styled from 'styled-components';
import BuyCard from './BuyCard';
import { observer } from 'mobx-react';
import Store from 'src/stores/common-store';
const StyledDiv = styled.div`
  width: 85%;
  background: transparent;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  self-alig: center;
  flex-direction: row;
  flex-wrap: wrap;
`;


const BuyCardsList = ({ data, page, variants, productVariants }) => {
  // console.log('BuyCardsList', data)
  let finalData = data?.schema_json;
  let content = {
    // sub_heading: 'What is ProLon®?',
    // heading: '',
    // description: 'ProLon® is a 5-day dietary program carefully designed to nourish and rejuvenate your body while supporting metabolic balance. The ProLon FastingMimicking Diet® gives many of the benefits of a fast while letting you eat delicious food to help fight hunger and lose weight while protecting lean body mass. Get a trimmer waistline and many of the associated benefits of fasting without requiring ongoing lifestyle changes, such as long-term dieting.*',
    // cardsList: [
    //     {
    //         id: 1,
    //         heading: "Perfect For First Time Faster",
    //         sub_heading: "Single box order",
    //         img_src: "https://cdn.shopify.com/s/files/1/0508/0625/9870/products/SingleBoxProLon_300x.jpg?v=1611171308",
    //         description: "Order ProLon as a trial and see what all the buzz is about. You’ll discover delicious foods that help keep you energized while giving your body the benefits of a 5-day fast. See how great you feel after doing just one cycle of ProLon!",
    //         price: "$199",
    //         sub_description: "Jumpstart your Metabolic Health and Healthy Aging with the Prolon 5-Day Meal Program",
    //         heading_color: "#9aca38",
    //         price_text_color: "#000",
    //         subscription: false
    //     },
    //     {
    //         id: 2,
    //         heading: "Enhanced Fasting",
    //         sub_heading: "Prolon <b>Subscription</b>",
    //         img_src: "//cdn.shopify.com/s/files/1/0508/0625/9870/products/ProLon_FMD_-_Product_Page_-_Subscription_300x.jpg?v=1611171308",
    //         description: "On-Going* Metabolic Health and Healthy Aging Maintenance Plans.",
    //         price: "$189",
    //         sub_description: "On-Going Healthy Aging Maintenance Plans",
    //         heading_color: "#fec91c",
    //         price_text_color: "#9aca37",
    //         subscription: true,
    //     },
    //     {
    //         id: 3,
    //         heading: "Ultimate Fast",
    //         sub_heading: "Prolon Plus 30 Free Intermittent Fasting Bars!",
    //         img_src: "https://cdn.shopify.com/s/files/1/0508/0625/9870/products/ProLon_FastBar_300x.jpg?v=1611171308",
    //         description: "Order ProLon and see what all the buzz is about. You’ll discover delicious foods that help keep you energized while giving your body the benefits of a 5-day fast. See how great you feel after doing just one cycle of ProLon!",
    //         price: "$224",
    //         sub_description: "Reset With This Ultimate Fasting Plan for Metabolic Health and Healthy Aging",
    //         heading_color: "#c535a3b3",
    //         price_text_color: "#9aca37",
    //         subscription: false
    //     },
    // ],
    ...finalData,
  };
  const products = content?.cards?.map((card) => {
    return (
      <BuyCard
        key={card.id}
        heading={card.heading}
        sub_heading={card.sub_heading}
        img_src={card.img_src}
        description={card.description}
        price={card.price}
        sub_description={card.sub_description}
        heading_color={card.heading_color}
        price_text_color={card.price_text_color}
        subscription={card.subscription}
        variants={productVariants}
        card={card}
        cardId={card.id}
      />
    );
  });
  return <StyledDiv id={content?.section_id}>{products}</StyledDiv>;
};

export default observer(BuyCardsList);
